import {
  BuildingOffice2Icon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  CreditCardIcon,
  CursorArrowRippleIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import SelectInput from "./SelectInput";

const tabs = [
  {
    id: 1,
    name: "Finance",
    code: "wpOGm",
    className: "bg-green-500/50 hover:bg-green-500/90",
    activeBorder: "lg:border-green-600",
    activeBg: "bg-green-500",
    icon: ChartPieIcon,
  },
  {
    id: 2,
    name: "Sales",
    code: "v2NEg",
    className: "bg-amber-500/50 hover:bg-amber-500/90",
    activeBorder: "lg:border-amber-600",
    activeBg: "bg-amber-500",
    icon: RocketLaunchIcon,
  },
  {
    id: 3,
    name: "Marketing",
    code: "xkPJn",
    className: "bg-indigo-500/50 hover:bg-indigo-500/90",
    activeBorder: "lg:border-indigo-600",
    activeBg: "bg-indigo-500",
    icon: CursorArrowRippleIcon,
  },
  {
    id: 4,
    name: "Real Estate",
    code: "NxjOv",
    className: "bg-blue-500/50 hover:bg-blue-500/90",
    activeBorder: "lg:border-blue-600",
    activeBg: "bg-blue-500",
    icon: BuildingOffice2Icon,
  },
  {
    id: 5,
    name: "Media Agency",
    code: "g5K5r",
    className: "bg-red-500/50 hover:bg-red-500/90",
    activeBorder: "lg:border-red-600",
    activeBg: "bg-red-500",
    icon: MegaphoneIcon,
  },
  {
    id: 6,
    name: "Capital Mgmt.",
    code: "j2M2y",
    className: "bg-cyan-500/50 hover:bg-cyan-500/90",
    activeBorder: "lg:border-cyan-600",
    activeBg: "bg-cyan-500",
    icon: CreditCardIcon,
  },
  {
    id: 7,
    name: "HR & Comp.",
    code: "k5K5J",
    className: "bg-rose-500/50 hover:bg-rose-500/90",
    activeBorder: "lg:border-rose-600",
    activeBg: "bg-rose-500",
    icon: ChatBubbleLeftRightIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const url = "http://localhost:3000/";
const url = "https://example.clearsquare.co/";

export default function App() {
  const [currentCode, setCurrentCode] = useState({});
  const [loadErrorCount, setLoadErrorCount] = useState(0); // add this state to track load error count
  const [iframeHtml, setIframeHtml] = useState(<></>);
  const iframeRef = React.useRef(null); // create a ref to use with the iframe

  // Create a new function for handling iframe load errors
  const handleIframeError = () => {
    if (loadErrorCount < 3) {
      setLoadErrorCount(loadErrorCount + 1);
      // eslint-disable-next-line no-self-assign
      iframeRef.current.src = iframeRef.current.src; // this triggers a reload
    }
  };

  const handleSelectInput = (option) => {
    setCurrentCode(option);
  };

  const getUrlValue = () => {
    const url = window.location.href;
    const match = url.match(/(?:example\.clearsquare\.co|localhost:\d+)\/([^/]+)/);
    if (match && match[1]) {
      let tab = tabs.find((tab) => tab.code === match[1]);
      if (tab) {
        setCurrentCode(tab);
      } else {
        setCurrentCode({
          name: "Other",
          code: match[1],
          className: "bg-gray-500 hover:bg-gray-500/90",
          activeBorder: "lg:border-gray-500",
          activeBg: "bg-gray-500",
          icon: ChartPieIcon,
        });
      }
      setIframeHtml(
        <iframe
          title="example-ceo-dashboards"
          ref={iframeRef}
          onError={handleIframeError}
          src={`https://public.domo.com/embed/pages/${currentCode?.code}`}
          width="100%"
          height="100%"
        />
      );
    }
  };

  useEffect(() => {
    getUrlValue();
  }, [currentCode, iframeHtml]);

  return (
    <div className="w-screen h-screen p-0 flex items-center flex-col">
      <div className="w-full lg:w-[1000px] relative h-full mt-4">
        <div className="lg:hidden">
          <SelectInput
            options={tabs}
            selected={currentCode}
            onChange={handleSelectInput}
          />
        </div>
        <div className="pl-3 w-full z-10 hidden lg:block ">
          <div>
            <nav
              className="-mb-px flex relative"
              aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={url + tab.code}
                  className={classNames(
                    "cursor-pointer text-white px-3.5 border-[3px] border-b-0 relative rounded-t-md transition-all duration-200 group inline-flex items-center py-4 text-sm font-medium",
                    tab.name === currentCode?.name
                      ? "shadow-md z-20 " + tab.activeBg + " " + tab.activeBorder
                      : "z-10 border-transparent " + tab?.className
                  )}
                  aria-current={tab.name === currentCode?.name ? "page" : ""}>
                  <tab.icon
                    className="text-white -ml-0.5 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span>{tab?.name}</span>
                </a>
              ))}
            </nav>
          </div>
        </div>
        <div
          className={classNames(
            "border-[3px] border-t-none h-full w-full bg-white lg:rounded-lg lg:shadow-lg",
            currentCode.activeBorder
          )}>
          <div className="h-full w-full lg:px-2 lg:py-2">{iframeHtml}</div>
        </div>
      </div>
    </div>
  );
}
